<template>
    <el-container>
        <el-header class="header">
            <div class="logo" @click="handleCollapse">
                <img :src="logo" alt="logo">
            </div>
            <div class="head">
                分时预约系统
            </div>
            <el-menu class="nav" noborder router :default-active="$route.path" unique-opened
                     background-color="#2081c0" text-color="#eee" active-text-color="white" mode="horizontal"
                     :collapse="isCollapse">
                <template v-for="(menu,index) in menuItems">
                    <el-submenu v-if="menu.children && menu.children.length >= 1" :index="menu.path" :key="index">
                        <template slot="title">
<!--                            <i :class="menu.icon"></i>-->
                            <span slot="title">{{ menu.label }}</span>
                        </template>
                        <el-menu-item v-for="(sb, sid) in menu.children" :key="sid" :index="sb.path">{{sb.label}}
                        </el-menu-item>
                    </el-submenu>
                    <el-menu-item v-else :index="menu.path" :key="index">
<!--                        <i :class="menu.icon"></i>-->
                        <span slot="title">{{ menu.label }}</span>
                    </el-menu-item>
                </template>
            </el-menu>
            <div class="user">
                <el-dropdown trigger="click">
                    <span class="username">
<!--                      <img src="//cdn.zhack.com/zkui/img/avatar.png" class="user-avatar"/>-->
                       {{$store.getters.username}}
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <router-link class="link" to="/main">
                            <el-dropdown-item>首页</el-dropdown-item>
                        </router-link>
                        <router-link class="link" to="/main/password">
                            <el-dropdown-item>修改密码</el-dropdown-item>
                        </router-link>
                        <router-link class="link" to="/logout">
                            <el-dropdown-item divided>退出登陆</el-dropdown-item>
                        </router-link>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </el-header>
        <el-container style="height: calc(100% - 60px);">
<!--            <el-aside width="">-->
<!--                <el-menu class="el-menu-vertical-demo" noborder router :default-active="$route.path" unique-opened-->
<!--                         :collapse="isCollapse">-->
<!--                    <template v-for="(menu,index) in menuItems">-->
<!--                        <el-submenu v-if="menu.children && menu.children.length >= 1" :index="menu.path" :key="index">-->
<!--                            <template slot="title">-->
<!--                                <i :class="menu.icon"></i>-->
<!--                                <span slot="title">{{ menu.label }}</span>-->
<!--                            </template>-->
<!--                            <el-menu-item v-for="(sb, sid) in menu.children" :key="sid" :index="sb.path">{{sb.label}}-->
<!--                            </el-menu-item>-->
<!--                        </el-submenu>-->
<!--                        <el-menu-item v-else :index="menu.path" :key="index">-->
<!--                            <i :class="menu.icon"></i>-->
<!--                            <span slot="title">{{ menu.label }}</span>-->
<!--                        </el-menu-item>-->
<!--                    </template>-->
<!--                </el-menu>-->
<!--            </el-aside>-->
            <el-main style="background-color: #f7f7f7">
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
    import Logo from "@/assets/logo0.png"
    import menu from "@/data/menu"

    export default {
        components: {},
        data() {
            return {
                logo: Logo,
                isCollapse: true,
                menuItems: [] //menu.menuItems
            }
        },
        methods: {
            handleCollapse() {
                this.isCollapse = !this.isCollapse
            },
            async getData() {
                const resp = await this.$http.get(`/auth/menu`)
                this.menuItems = resp.data.data
                if (this.$store.getters.username === 'admin') {
                    this.menuItems = menu.menuItems
                }
            }
        },
        mounted() {
            this.getData()
            let clientWidth = document.documentElement.clientWidth || document.body.clientHeight;
            if (clientWidth < 768) {//当屏幕小于768时，设置Vuex里的数据为true
                this.isCollapse = true
            } else {//反之，设置Vuex里的数据为false
                this.isCollapse = false
            }
        }
    }
</script>

<style lang="scss" scoped>
    .header {
        background: #2081c0;
        border-bottom: 1px solid #2081c0;
        color: #fff;
        display: flex;
        flex-direction: row;
        height: 62px;
        line-height: 60px;

        .logo {
            vertical-align: middle;
            display: flex;
            align-items: center;
            color: #ffffff;
            text-decoration: none;

            img {
                width: 30px;
                height: 30px;
                border-radius: 5px;
                margin-right: 10px;
            }
        }

        .head {
            width: 141px;
        }

        .nav {
            flex: 1;
            overflow: hidden;
        }
        .el-menu.el-menu--horizontal {
            /* border-bottom: solid 1px #e6e6e6; */
            border-bottom: none;
        }
        v-deep .el-submenu__title i {
            color: #ffffff;
        }

        .user {
            text-align: right;
            width: 180px;

            .username {
                color: #ffffff;
                cursor: pointer;
                vertical-align: middle;
                display: flex;
                align-items: center;

                .user-avatar {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }

    .link {
        text-decoration: none;
    }

    .el-aside {
        /*background-color: #eeeeee;*/
        color: #333;
        border-right: 1px solid #e6e6e6;
    }

    .el-menu-vertical-demo:not(.el-menu--collapse) {
        width: 200px;
        min-height: 400px;
        border: none;
    }
    el-submenu__icon-arrow::v-deep el-icon-arrow-down::v-deep {
        color:white !important;
    }

    /*.el-menu-item.is-active::v-deep{*/
    /*    color: green;*/
    /*    border-bottom-color:red!important;*/
    /*    font-size: 16px;*/
    /*}*/

    /*.el-submenu__title::v-deep {*/
    /*    color: green;*/
    /*    border-bottom-color:red!important;*/
    /*    font-size: 16px;*/
    /*}*/
</style>
